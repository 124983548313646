import { sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  container: {
    height: 48,
    backgroundColor: theme.lightAccent,
    paddingHorizontal: 12,
    alignItems: 'center',
    flexDirection: 'row',
    columnGap: sizes.SPACING_S,
  },
  text: {
    color: theme.text,
    flex: 1,
  },
  headerButton: {
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderRadius: sizes.SPACING_S,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: sizes.SPACING_XS,
  },
  headerButtonText: {
    color: theme.placeholder,
  },
  headerButtonTextHover: {
    color: theme.lightAccent,
  },
  disabled: {
    opacity: 0.5,
  },
}));
